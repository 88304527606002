/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file manage devices in call controls button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.ManageDevicesButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import Gear from "~/icons/gear";
import { uiActions, useUIState } from "~/state";
import ControlButton from "./ControlButton";

// button's ID in HTML used to focus it from BaseMenu
export const manageDevicesButtonId = "devices-button";

/** String tokens used by ManageDevicesButton component */
enum TokenNames {
	label = "Devices",
	ariaLabel = "ManageDevices",
}

interface IProps {
	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}

/**
 * The ManageDevicesButton component
 */
const ManageDevicesButton: FC<IProps> = (props) => {
	const { tabString } = props;
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const dispatch = useDispatch();

	const strings = useStrings("ManageDevicesButton", Object.values(TokenNames));

	const onClick = useCallback(() => {
		dispatch(uiActions.toggleVisibleMenu({ menu: "devices" }));
	}, [dispatch]);

	const pressed = visibleMenu === "devices";

	return (
		<ControlButton
			id={manageDevicesButtonId}
			icon={Gear}
			ariaLabel={strings[TokenNames.ariaLabel] + String(tabString)}
			onClick={onClick}
			ariaExpanded={pressed}
			keyboardShortcut={["alt", "v"]}
			supplementaryIcon="continue"
			buttonText={strings[TokenNames.label]}
		/>
	);
};

ManageDevicesButton.displayName = "ManageDevicesButton";

export default ManageDevicesButton;
