/**
 * @copyright Copyright 2020-2023 Epic Systems Corporation
 * @file Component used for device configuration in hardware setup
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.DeviceSetupRow
 */

import React, { ComponentType, FC, useContext, useEffect, useRef, useState } from "react";
import Collapse from "~/components/Utilities/Collapse";
import { MenuContextProvider } from "~/components/Utilities/MenuContext";
import { DeviceStatus, DisplayContext, ITestable } from "~/types";
import { resolveClassName } from "~/utils/className";
import { getPseudoRandomId } from "~/utils/general";
import { HardwareSetupDisplayContext } from "../HardwareSetup";
import HardwareSetupRow from "../HardwareSetupRow";
import HardwareSetupStatusIcon from "../HardwareSetupStatusIcon";
import styles from "./DeviceSetupRow.module.scss";

/**
 * Props for DeviceSetupRow Component
 */
interface IProps extends ITestable {
	icon: ComponentType;
	labelText: string;
	status: DeviceStatus;
	deviceSelectorComponent: React.FunctionComponent;
	statusMessage?: string;
}

/**
 * The DeviceSetupRow component
 * @param props The props ;)
 */
const DeviceSetupRow: FC<IProps> = (props) => {
	const {
		icon: Icon,
		labelText,
		status,
		statusMessage,
		deviceSelectorComponent: DeviceSelectorComponent,
		children,
		...mainProps
	} = props;

	const context = useContext(HardwareSetupDisplayContext);
	const inDevicesTray = context === DisplayContext.devicesTray;
	const inHardwareTest = context === DisplayContext.lobby || context === DisplayContext.standAlone;

	const [showStatusMsg, setShowStatusMsg] = useState(false);
	const statusMsgFallback = useRef<string>();

	useEffect(() => {
		// keep track of the previous status message, so it will animate smoothly when closing
		statusMsgFallback.current = statusMessage ?? statusMsgFallback.current;

		// the Collapse component expanding won't be smooth if based off statusMessage directly
		// setting showStatusMsg here allows one repaint for the Collapse to size its contents properly
		setShowStatusMsg(!!statusMessage);
	}, [statusMessage]);

	const rowClassName = resolveClassName(styles, {
		deviceSetupRow: true,
		darkTheme: inDevicesTray,
	});

	const labelIdRef = useRef(getPseudoRandomId()); // Make label ID a ref to avoid recalculations per render

	if (inHardwareTest) {
		return (
			<div
				role="group"
				aria-labelledby={labelIdRef.current}
				className={styles["hardwareTestRow"]}
				{...mainProps}
			>
				<MenuContextProvider isInMenu>
					<div id={labelIdRef.current} className={styles["hardwareTestRowLabel"]}>
						{labelText}
					</div>
					<div className={styles["baseRow"]}>
						<HardwareSetupStatusIcon icon={Icon} status={status} />
						<div className={styles["dropdownAndMessage"]}>
							<DeviceSelectorComponent />
							<div className={styles["deviceStatusMessage"]}>
								{statusMessage ?? statusMsgFallback.current}
							</div>
						</div>
					</div>
				</MenuContextProvider>
			</div>
		);
	}

	return (
		<div className={rowClassName} {...mainProps}>
			<div className={styles["baseRow"]}>
				<HardwareSetupRow icon={Icon} status={status} label={labelText}>
					{children}
				</HardwareSetupRow>
				<DeviceSelectorComponent />
			</div>
			<Collapse collapsed={!showStatusMsg}>
				<div className={styles["deviceStatusMessage"]}>
					{statusMessage ?? statusMsgFallback.current}
				</div>
			</Collapse>
		</div>
	);
};

DeviceSetupRow.displayName = "DeviceSetupRow";

export default DeviceSetupRow;
