/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file The menu button to open the About EVC menu (copyright notice)
 * @author Sasha Chackalackal
 * @module Epic.VideoApp.Components.Header.Buttons.AboutEvcButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { ComponentType, FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { IconProps } from "~/icons";
import InformationCircle from "~/icons/informationCircle";
import { uiActions, useUIState } from "~/state";
import { resolveClassName } from "~/utils/className";
import styles from "../ControlsHeader.module.scss";
import ControlButton from "./ControlButton";

// button's ID in HTML used to focus it from BaseMenu
export const aboutEvcButtonId = "about-evc-button";

/** String tokens used by AboutEvcButton component */
enum TokenNames {
	label = "Label",
}

interface IProps {
	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}

const MenuInfoCircleIcon: ComponentType<IconProps> = () => {
	return (
		<InformationCircle
			baseColor="#0000"
			fillColor="white"
			outlineColor="white"
			height={30}
			width={30}
			aria-hidden
		/>
	);
};

/**
 * The AboutEvcButton component
 */
const AboutEvcButton: FC<IProps> = (props) => {
	const { tabString } = props;
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const dispatch = useDispatch();
	const strings = useStrings("AboutEvcButton", Object.values(TokenNames));

	const onClick = useCallback(() => {
		dispatch(
			uiActions.toggleVisibleMenu({
				menu: "about",
			}),
		);
	}, [dispatch]);

	const pressed = visibleMenu === "about";
	const className = resolveClassName(styles, {
		buttonActive: pressed,
	});

	return (
		<ControlButton
			id={aboutEvcButtonId}
			icon={MenuInfoCircleIcon}
			ariaLabel={strings[TokenNames.label] + String(tabString)}
			onClick={onClick}
			keyboardShortcut={["alt", "h"]}
			ariaExpanded={pressed}
			buttonClassName={className}
			supplementaryIcon="continue"
			buttonText={strings[TokenNames.label]}
		/>
	);
};

AboutEvcButton.displayName = "AboutEvcButton";

export default AboutEvcButton;
