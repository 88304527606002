/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file A hidden div used to read screen reader only accessibility alerts
 * @author Jonathon Moore
 * @module Epic.VideoApp.Components.Alerts.AccessibilityAlert
 */

import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { useStrings } from "~/hooks";
import { alertActions, useAlertState } from "~/state/alerts";
import { stringFormat } from "~/utils/strings";
import styles from "./Alerts.module.scss";

export enum AccessibilityAlertTestingIds {
	self = "AccessibilityAlert",
}

enum TokenNames {
	backgroundSet = "BackgroundSet",
	backgroundCleared = "BackgroundCleared",
	admittanceAlert = "AdmittanceAlert",
	backgroundSetDefault = "BackgroundSetDefault",
}

/*
The accessibility alert is used to add screen reader alerts to workflows that are ad-hoc. Here are the steps to add an alert

1. Create a new alert state in src/state/alerts.ts
  *  Add the alert to IAlertState and initialize it in getInitialState()
  *  Create a getter and setter for this new alert state
  *  In buildShareState() add the setter to reducers and getter to selectors

2. Add a string for the AccessibilityAlert component to read in postAuth.resx

3. In AccessibilityAlert.tsx
  *  Add the new alert string to TokenNames
  *  Get the alert state and store it to a local variable
  *  call useAlert with the following values as inputs
    *  Add the logic for when the alert should be read out
	*  Add an array of actions that should be taken when resetting the state to it's initial value
	*  Add the string that should be read out
	*  Add the setText state call for use in the hook
	*  Add the optional delay you want to be present before the alert is read (this may be necessary based on workflow / platform)
	*  Add the optional amount of time you want the string to be present before removed 
	
4. In AccessibilityAlert.test.tsx
  * Add the new alert to mockAlertSelectors
  * Add a unit test case for the new alert
*/

const AccessibilityAlert: FC = () => {
	// Get alert states
	const alertAdmitted = useAlertState((selectors) => selectors.getAdmittedAlert(), []);

	const alertBackground = useAlertState((selectors) => selectors.getBackgroundChangedAlert(), []);

	const displayModeAlert = useAlertState((selectors) => selectors.getDisplayModeAlert(), []);

	const backgroundName = useAlertState((selectors) => selectors.getBackgroundName(), []);

	const alertBackgroundCleared = useAlertState((selectors) => selectors.getBackgroundClearedAlert(), []);

	const [text, setText] = useState("");

	const strings = useStrings("AccessibilityAlert", Object.values(TokenNames));

	// Set up alerts
	useAlert(
		alertAdmitted,
		[alertActions.setAdmittedAlert(false)],
		strings[TokenNames.admittanceAlert],
		setText,
		1,
		200,
	);

	useAlert(
		alertBackground,
		[alertActions.setBackgroundChangedAlert(false), alertActions.setBackgroundName("")],
		backgroundName !== "unknown"
			? stringFormat(strings[TokenNames.backgroundSet], backgroundName)
			: strings[TokenNames.backgroundSetDefault],
		setText,
	);

	useAlert(
		alertBackgroundCleared,
		[alertActions.setBackgroundClearedAlert(false), alertActions.setBackgroundName("")],
		strings[TokenNames.backgroundCleared],
		setText,
	);

	useAlert(displayModeAlert?.length > 0, [alertActions.setDisplayModeAlert("")], displayModeAlert, setText);

	return (
		<div
			className={styles["screenReaderOnly"]}
			aria-live="assertive"
			data-testid={AccessibilityAlertTestingIds.self}
		>
			{text}
		</div>
	);
};

/**
 * Checks to see if an alert should be read out by a screenreader if so show the alert for a specified amount of time before resetting the text and state.
 * @param alertState The boolean statement that should be checked to trigger the alert
 * @param clearActions The array of values that need to be reset when the value is cleared
 * @param text The string to be read out aloud by the screen reader
 * @param setText The setText State function that is used to update the aria-live div
 * @param initialDelay how long to delay the initial setting of the string.
 * @param removalDelay how long to leave the string visible before removing it.
 */
const useAlert = (
	alertState: boolean,
	clearActions: Action[],
	text: string,
	setText: (arg0: string) => void,
	initialDelay = 0,
	removalDelay = 200,
): void => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (alertState) {
			if (initialDelay > 0) {
				setTimeout(() => {
					setText(text);
				}, initialDelay);
			} else {
				setText(text);
			}

			setTimeout(() => {
				for (const action of clearActions) {
					dispatch(action);
				}

				setText("");
			}, removalDelay);
		}
	}, [alertState, text, setText, dispatch, initialDelay, removalDelay, clearActions]);
};

AccessibilityAlert.displayName = "AccessibilityAlert";

export default AccessibilityAlert;
