/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Display Mode Button
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Buttons.DisplayModeButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import Collaborative from "~/icons/collaborative";
import Grid from "~/icons/grid";
import { alertActions, combinedActions, uiActions, useUIState } from "~/state";
import ControlButton from "./ControlButton";

enum TokenNames {
	switchToActiveSpeakerText = "ToggleBase",
	switchToGridText = "ToggleGrid",
	speakerViewShown = "SpeakerViewShown",
	gridViewShown = "GridViewShown",
}

interface IProps {
	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}
/**
 * DisplayMode toggle button
 */
const DisplayModeButton: FC<IProps> = (props) => {
	const { tabString } = props;
	const currentDisplayType = useUIState((selectors) => selectors.getVideoLayout(), []);
	const dispatch = useDispatch();

	const strings = useStrings("DisplayModeButton", Object.values(TokenNames));

	const toggleViewMode = useCallback(() => {
		/*For accessibility purposes, the order of events is as follows:
		  1. Announce the change in display mode
		  2. Change focus to the more options button, so we don't announce "blank" when the active object hides
		  3. Hide the opened more options menu
		*/
		dispatch(
			alertActions.setDisplayModeAlert(
				currentDisplayType !== "Active Speaker"
					? strings[TokenNames.speakerViewShown]
					: strings[TokenNames.gridViewShown],
			),
		);
		window.setTimeout(() => {
			document.getElementById("more-options")?.focus();
			dispatch(uiActions.toggleVideoLayout());
		}, 50);
		window.setTimeout(() => dispatch(uiActions.toggleVisibleMenu({ menu: null })), 100);

		// Prevents image capture ready toast from sticking around
		dispatch(combinedActions.clearImageCaptureActive());
	}, [currentDisplayType, dispatch, strings]);

	const icon = currentDisplayType === "Active Speaker" ? Grid : Collaborative;
	const toggleText =
		currentDisplayType !== "Active Speaker"
			? strings[TokenNames.switchToActiveSpeakerText]
			: strings[TokenNames.switchToGridText];

	return (
		<ControlButton
			icon={icon}
			onClick={toggleViewMode}
			ariaLabel={toggleText + String(tabString)}
			keyboardShortcut={["alt", "w"]}
			buttonText={toggleText}
			pressed={false}
		/>
	);
};

DisplayModeButton.displayName = "DisplayModeButton";

export default DisplayModeButton;
