/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file an option to be displayed in the language selector component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.LanguageSelector.LanguageOption
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { alertActions, useSettingsState } from "~/state";
import { ILocale } from "~/types/locale";
import { resolveClassName } from "~/utils/className";
import SpinnerIcon from "../Loading/SpinnerIcon";
import styles from "./LanguageSelector.module.scss";

/**
 * Props for LanguageOption Component
 */
interface IProps {
	/** The locale */
	locale: ILocale;
	/** Whether or not this option is currently selected */
	isSelected: boolean;
	/** Callback function for when this language is selected */
	onSelected: (localeCode: string) => Promise<void>;
}

export enum LanguageOptionTestIds {
	self = "LanguageOption",
	radioButton = "RadioButton",
}

/**
 * The LanguageOption component
 * @param props The props ;)
 */
const LanguageOption: FC<IProps> = (props) => {
	const { locale, isSelected, onSelected } = props;
	const { localeCode, displayName, ariaLabel } = locale;
	const loadingLocale = useSettingsState((selectors) => selectors.getLoadingLocale(), []);
	const isLoading = loadingLocale === localeCode;
	const greyOutOption = !!loadingLocale && loadingLocale !== localeCode;
	const dispatch = useDispatch();

	const onButtonClick = useCallback(() => onSelected(localeCode), [onSelected, localeCode]);
	const radioButtonClass = resolveClassName(styles, {
		icon: true,
		filled: isSelected,
		disabled: greyOutOption,
	});
	const languageRowClass = resolveClassName(styles, { languageRow: true, disabled: greyOutOption });
	const isLoadingRef = useRef(isLoading);

	useEffect(() => {
		isLoadingRef.current = isLoading;
	}, [isLoading]);

	useEffect(() => {
		function setLoadingToast(): void {
			if (isLoadingRef.current) {
				dispatch(
					alertActions.postToastAlert({
						type: "language-loading",
						messageToken: "LoadingLanguage",
						messageArgs: [localeCode, displayName],
						toastStyle: "language-loading",
					}),
				);
			}
		}
		if (isLoadingRef.current) {
			setTimeout(setLoadingToast, 400);
		}
	}, [dispatch, displayName, localeCode, isLoading]);

	return (
		<button
			type="button"
			lang={localeCode}
			className={languageRowClass}
			onClick={onButtonClick}
			disabled={!!loadingLocale}
			aria-label={ariaLabel}
			aria-pressed={isSelected}
			data-testid={LanguageOptionTestIds.self}
		>
			<div className={styles["iconAndLabelWrapper"]} tabIndex={-1}>
				{isLoading ? (
					<SpinnerIcon small />
				) : (
					<div className={radioButtonClass} data-testid={LanguageOptionTestIds.radioButton} />
				)}
				{displayName}
			</div>
		</button>
	);
};

LanguageOption.displayName = "LanguageOption";

export default LanguageOption;
