/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Shared utilites for unwrapping non-vendor specific errors
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Functions.Errors
 */

import { SessionErrorCodes, VendorError, vendorFailureCode } from "../interfaces";

/**
 * Handle errors to initialize the vendor connection.
 *
 * @param input error output, may be a string, error object, or other unknown type
 * @returns - A mocked out Vendor Error with as much information as we can parse
 */
export function unwrapInitializeError(input: unknown): VendorError {
	// Daily seems to spit out only a string that includes some useful info "failed to load object bundle"
	// Try to fill in Vendor Error information when possible, otherwise include the error string for more visibility
	if (typeof input === "string") {
		return new VendorError(
			vendorFailureCode,
			SessionErrorCodes.failedToAcquireVendor,
			input,
			"",
			true,
			true,
		);
	} else {
		const inputError = input as Error;
		return new VendorError(
			vendorFailureCode,
			SessionErrorCodes.failedToAcquireVendor,
			inputError?.message,
			inputError?.name,
			true,
			true,
		);
	}
}
