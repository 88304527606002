/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Handler that requests and stores translations from the server
 * @author Razi Rais
 * @module Epic.VideoApp.Utils.I18n.I18n
 */

import { IAction } from "@epic/react-redux-booster";
import { combinedActions } from "~/state";
import { ENABLED_LOCALES, ENGLISH_US_LOCALE, IDomElements, ILocale, ITranslationInfo } from "~/types/locale";
import { makeRequest } from "~/utils/request";
import { recentLanguageKey, setCookie } from "../cookies";
import { hoursToMs } from "../dateTime";
import { debug } from "../logging";
import {
	getDomElements,
	getPreferredLocale,
	getTranslationJson,
	languageCode,
	resolveLocale,
} from "./i18nUtils";

export class I18nClass {
	private _fallbackLocale: string;
	private _translations: Record<string, ITranslationInfo>;
	private _domElements: IDomElements;
	private _currDirIsRtl: boolean;

	constructor() {
		this._fallbackLocale = ENGLISH_US_LOCALE;
		this._translations = {};
		this._domElements = getDomElements();
		this._currDirIsRtl = false; // default direction

		Object.entries(getTranslationJson()).forEach(([key, value]) => {
			const languageInfo = ENABLED_LOCALES[key];
			const displayName = languageInfo?.displayName ?? key.toUpperCase();
			const ariaLabel = languageInfo?.ariaLabel
				? `${displayName} ${languageInfo?.ariaLabel}`
				: displayName;

			this._translations[key] = {
				displayName: displayName,
				ariaLabel: ariaLabel,
				isRtl: !!languageInfo?.isRtl,
				translation: value,
				includesPostAuth: !!languageInfo?.includesPostAuth,
			};
		});

		// Set the direction based on the user's preferred locale
		const preferredLocale = getPreferredLocale();
		this._setDirection(this._translations[preferredLocale]?.isRtl || false);
	}

	/**
	 * Get a list of the available locales
	 * @returns a list of the locales that are available
	 */
	getLocales(): ILocale[] {
		const locales: ILocale[] = Object.entries(this._translations).map(([key, value]) => ({
			localeCode: key,
			displayName: value.displayName,
			ariaLabel: value.ariaLabel,
		}));
		return locales.sort(compareLocale);
	}

	/**
	 * Determine whether or not changing locales should be enabled
	 * @returns true if there is more than one supported locale, false otherwise
	 */
	canChangeLocales(): boolean {
		return Object.keys(this._translations).length > 1;
	}

	/**
	 * Returns the translation string for a token
	 *
	 * @param key The token name for the string being accessed.
	 * @param localeCode The locale to use when obtaining the string
	 * @returns The string in the desired locale
	 */
	getString(key: string, localeCode: string): string {
		return (
			this._translations[localeCode]?.translation[key] ||
			this._translations[this._fallbackLocale]?.translation[key] ||
			key
		);
	}

	/**
	 * Sets the new locale and saves the new translation json, dispatches the update to state and subsequently updates local storage.
	 * @param localeCode the locale to be set
	 */
	async setLocale(localeCode: string, dispatch: <T extends IAction>(action: T) => T): Promise<void> {
		const resolvedLocale = resolveLocale(localeCode);
		if (!resolvedLocale) {
			debug(`Locale ${localeCode} is not supported `);
			return;
		}
		setCookie(recentLanguageKey, resolvedLocale, hoursToMs(1));

		this._setHTMLAttribute(localeCode);

		// request translation json from the server
		await this._getLocaleInfo(resolvedLocale);
		const directionProperlySet = this._setDirection(this._translations[resolvedLocale].isRtl);
		if (directionProperlySet) {
			dispatch(combinedActions.setLocale(resolvedLocale));
		}
		this._setHTMLAttribute(localeCode);
	}

	/**
	 * Check if translations have been loaded for the locale and if not, make the request to load both translations and text direction.
	 * @param localeCode the locale to get direction and translations for
	 */
	async _getLocaleInfo(localeCode: string): Promise<void> {
		// if translation has already been loaded to the client, no need to make server request
		if (this._translations[localeCode] && this._translations[localeCode].includesPostAuth) {
			return;
		}

		const postAuthTranslation = await loadPostAuthStrings(localeCode);
		if (postAuthTranslation) {
			this._translations[localeCode].translation = {
				...this._translations[localeCode]?.translation,
				...postAuthTranslation,
			};
			this._translations[localeCode].includesPostAuth = true;
		}
	}

	/**
	 * Check if the current direction is already set to the desired UI direction and if not, set the UI to the given direction.
	 * @param direction the text direction of the current locale.
	 */
	_setDirection(isRtl: boolean): boolean {
		// If this direction is already set don't re-set direction info
		if (isRtl === this._currDirIsRtl) {
			return true;
		}

		if (this._domElements.htmlEl) {
			const directionKey = isRtl ? "rtl" : "ltr";
			this._domElements.htmlEl.setAttribute("dir", directionKey);
			this._currDirIsRtl = isRtl;
			return true;
		}

		return false;
	}

	/**
	 * Update the HTML Lang element based on the locale that has been selected
	 * @param locale the locale that is currently being set
	 */
	_setHTMLAttribute(locale: string): void {
		if (this._domElements.htmlEl) {
			if (locale === "zh-ushans") {
				this._domElements.htmlEl.setAttribute("lang", "zh-Hans");
			} else if (locale === "zh-ushant") {
				this._domElements.htmlEl.setAttribute("lang", "zh-Hant");
			} else {
				this._domElements.htmlEl.setAttribute("lang", languageCode(locale));
			}
		}
	}
}

export const I18n = new I18nClass();

/**
 * Loads a boolean indicating whether the locale is rtl and a json containing the translation strings for a specified locale
 * @param localeCode The locale to load information from
 * @returns an ILocaleResponse with the desired direction and translation information
 */
async function loadPostAuthStrings(localeCode: string): Promise<Record<string, string>> {
	return makeRequest<Record<string, string>>("/api/Locale", "GET", null, undefined, {
		queryStringData: { locale: localeCode },
	});
}

/**
 * Compare two locales for sorting
 * @param a first locale in the comparison
 * @param b second locale in the comparison
 * @returns -1 if a should come before b, 1 if b should come before a, 0 if neutral
 */
function compareLocale(a: ILocale, b: ILocale): number {
	if (a.localeCode === ENGLISH_US_LOCALE) {
		return -1;
	}
	if (b.localeCode === ENGLISH_US_LOCALE) {
		return 1;
	}
	if (a.localeCode.startsWith("zz-epic")) {
		return 1;
	}
	if (b.localeCode.startsWith("zz-epic")) {
		return -1;
	}
	return a.localeCode.localeCompare(b.localeCode);
}
