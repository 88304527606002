/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Utilities for managing hardware test
 * @author Echo Cologlu
 * @module Epic.VideoApp.Utils.HardwareTest
 */

import {
	DeviceStatus,
	DeviceStatusSubtype,
	EpicReportableCameraDeviceStatus,
	EpicReportableMicDeviceStatus,
	EpicReportableMicDeviceStatusSubtype,
	EpicReportableSpeakerDeviceStatus,
	IDeviceStatus,
	IHardwareTestResult,
} from "~/types";

/**
 * Given two hardware test devices, returns true if they are equal (or just enabling/disabling device), false otherwise
 * Because of no-prototype-builtins eslint rule, I can't directly use hasOwnProperty method,
 * which makes the function a bit more complicated than it has to be
 * @param object1 IDeviceStatus
 * @param object2 IDeviceStatus
 * @returns true if the objects are equal, false otherwise
 */
export function areObjectsEqual(object1: IDeviceStatus, object2: IDeviceStatus): boolean {
	const keys1 = Object.keys(object1) as (keyof IDeviceStatus)[];
	const keys2 = Object.keys(object2) as (keyof IDeviceStatus)[];

	if (keys1.length !== keys2.length) {
		return false;
	}

	return keys1.every((key) => {
		if (key === "label") {
			return (
				object1.label.trim() === object2.label.trim() ||
				object1.label.trim() === "" ||
				object2.label.trim() === ""
			); //If one of the labels is empty, we still consider them equal. (It may mean enabling/disabling the same camera/mic/speaker during hardware test)
		}
		return Object.prototype.hasOwnProperty.call(object2, key) && object1[key] === object2[key];
	});
}

/**
 * If this is a subsequent hardware test, we only want to log to LVV if there was a change in the hardware status.
 * We will log all the failed hardware tests even if they are subsequent tests.
 * @param prevTest Previous hardware test results.
 * @param currentTest Current hardware test results.
 * @param isConnectedToCall Whether the user is connected to a call (i.e. not in the hardware test screen).
 * @returns true if we should log the test to LVV, false otherwise.
 */
export function shouldLogToEpic(
	prevTest: IHardwareTestResult | null,
	currentTest: IHardwareTestResult,
	isConnectedToCall: boolean,
): boolean {
	if (prevTest === null) {
		return true; // First test should always be logged
	}
	const hardwareChanged =
		!areObjectsEqual(prevTest.camera, currentTest.camera) ||
		!areObjectsEqual(prevTest.microphone, currentTest.microphone) ||
		!areObjectsEqual(prevTest.speaker, currentTest.speaker);

	return !prevTest.success || !currentTest.success || (hardwareChanged && !isConnectedToCall);
}

/**
 * Constructs an error message for granular device error logging to LVV and Management portal
 * @param cameraError The camera error
 * @param micError The microphone error
 * @param speakerError The speaker error
 * @returns Error message string in the form of "Device1 (error type1), Device2 (error type 2)", empty string if no errors
 */
export function constructErrorMessageForHWTest(
	cameraError: DeviceStatusSubtype,
	micError: DeviceStatusSubtype,
	speakerError: DeviceStatusSubtype,
): string {
	// These object keys will be used in the string, hence they are capitalized
	/* eslint-disable @typescript-eslint/naming-convention */
	const deviceErrors = {
		Camera: cameraError,
		Microphone: micError,
		Speaker: speakerError,
	};

	const errors = Object.entries(deviceErrors).reduce((errorMessages: string[], [device, error]) => {
		if (error !== DeviceStatusSubtype.none) {
			errorMessages.push(`${device} (${error})`);
		}
		return errorMessages;
	}, []);

	return errors.join(", ");
}

/**
 * Determines if the mic status classifies as a reportable failure
 * @param micStatus The mic status
 * @param micError The mic error
 * @returns true if the mic status is a failure, false otherwise
 */
export function isReportableMicFailure(micStatus: DeviceStatus, micError: DeviceStatusSubtype): boolean {
	return (
		EpicReportableMicDeviceStatus.includes(micStatus) &&
		EpicReportableMicDeviceStatusSubtype.includes(micError)
	);
}

/**
 * Filters out non-reportable mic errors
 * @param micError The mic error
 * @returns the filtered mic error
 */
export function getReportableMicError(micError: DeviceStatusSubtype): DeviceStatusSubtype {
	return EpicReportableMicDeviceStatusSubtype.includes(micError) ? micError : DeviceStatusSubtype.none;
}

/**
 * Determines if the camera status classifies as a reportable failure
 * @param cameraStatus The camera status
 * @returns true if the camera status is a failure, false otherwise
 */
export function isReportableCameraFailure(cameraStatus: DeviceStatus): boolean {
	return EpicReportableCameraDeviceStatus.includes(cameraStatus);
}

/**
 * Determines if the speaker status classifies as a reportable failure
 * @param speakerStatus The speaker status
 * @returns true if the speaker status is a failure, false otherwise
 */
export function isReportableSpeakerFailure(speakerStatus: DeviceStatus): boolean {
	return EpicReportableSpeakerDeviceStatus.includes(speakerStatus);
}
