/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file change background in call controls button
 * @author Liam Liden
 * @module Epic.VideoApp.Components.Header.Buttons.ChangeBackgroundButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import blurryBackground from "~/icons/blurryBackground";
import { uiActions, useBackgroundProcessorsState, useUIState } from "~/state";
import ControlButton from "./ControlButton";

// button's ID in HTML used to focus it from BaseMenu
export const changeBackgroundButtonId = "background-button";

enum TokenNames {
	changeBackground = "ChangeBackground",
	backgrounds = "Backgrounds",
}

interface IProps {
	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}

/**
 * The ChangeBackgroundButton component
 * @param props The props ;)
 */
const ChangeBackgroundButton: FC<IProps> = (props) => {
	const { tabString } = props;
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const isProcessorsLoaded =
		useBackgroundProcessorsState((selectors) => selectors.getProcessorLoadStatus(), []) === "finished";

	const dispatch = useDispatch();

	const strings = useStrings("ChangeBackgroundButton", Object.values(TokenNames));

	const onClick = useCallback(() => {
		dispatch(uiActions.toggleVisibleMenu({ menu: "background" }));
	}, [dispatch]);

	if (!isProcessorsLoaded) {
		return null;
	}

	const pressed = visibleMenu === "background";

	return (
		<ControlButton
			id={changeBackgroundButtonId}
			icon={blurryBackground}
			ariaLabel={strings[TokenNames.changeBackground] + String(tabString)}
			onClick={onClick}
			ariaExpanded={pressed}
			keyboardShortcut={["alt", "b"]}
			supplementaryIcon="continue"
			buttonText={strings[TokenNames.backgrounds]}
		/>
	);
};

ChangeBackgroundButton.displayName = "ChangeBackgroundButton";

export default ChangeBackgroundButton;
