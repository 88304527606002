/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to build a representation of a single hardware test state (pass or fail)
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseHardwareTestResult
 */
import { useContext, useEffect, useState } from "react";
import { useConnectionStatus } from "~/hooks/useConnectionStatus";
import { useHardwareTestState, useLocalTrackState, useSpeakerState } from "~/state";
import { HardwareTestStatus, IHardwareTestResult } from "~/types";
import {
	constructErrorMessageForHWTest,
	getReportableMicError,
	isReportableCameraFailure,
	isReportableMicFailure,
	isReportableSpeakerFailure,
	shouldLogToEpic,
} from "~/utils/hardwareTest";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";

/**
 * Makes a server request with local track data from redux store
 * @param isVideoCall flag to indicate that we are getting the result for the video call
 */
export function useHardwareTestResult(isVideoCall: boolean): IHardwareTestResult | null {
	const [hardwareTest, setHardwareTest] = useState<IHardwareTestResult | null>(null);
	const { session, localDeviceStream } = useContext(VideoSessionContext);
	const connectionStatus = useConnectionStatus(session);
	const isVideoEnabled = useIsStreamEnabled("video", localDeviceStream);
	const hasAutoSelected = useLocalTrackState((selectors) => selectors.getHasAutoSelected(), []);
	const selectedCameraLabel = localDeviceStream?.getDeviceName("video") ?? "";
	const selectedMicLabel = localDeviceStream?.getDeviceName("audio") ?? "";
	const selectedSpeakerLabel = useSpeakerState((selectors) => selectors.getSelectedSpeakerLabel(), []);
	const status = useHardwareTestState(
		(selectors) => selectors.getTestStatus({ allowOneError: isVideoCall, isStandalone: !isVideoCall }),
		[isVideoCall],
	);
	const cameraError = useHardwareTestState((selectors) => selectors.getCameraError(), []);
	const cameraFailure = isReportableCameraFailure(
		useHardwareTestState((selectors) => selectors.getCameraStatus(isVideoCall), [isVideoCall]),
	);
	const micError = getReportableMicError(
		useHardwareTestState((selectors) => selectors.getMicrophoneError(), []),
	);
	const micFailure = isReportableMicFailure(
		useHardwareTestState((selectors) => selectors.getMicrophoneStatus(isVideoCall), [isVideoCall]),
		useHardwareTestState((selectors) => selectors.getMicrophoneError(), []),
	);
	const speakerError = useHardwareTestState((selectors) => selectors.getSpeakerError(), []);
	const speakerFailure = isReportableSpeakerFailure(
		useHardwareTestState((selectors) => selectors.getSpeakerStatus(), []),
	);
	const vendorError = useHardwareTestState((selectors) => selectors.getVendorError(), []);

	// With the vendor abstraction layer refactor, the new useMediaTrack hook only updates the media localDeviceStream track when a
	// device becomes ready (like switching to a new device). Because of that, only device updates that affect
	// the hardware test result are logged to the portal. We use the useMediaTrack hook to know when the device
	// has changed, and the useIsStreamEnabled hook to know when the device is enabled/disabled. Note: we do not clear the mic
	// from the portal when it is muted, which we do for when the camera is disabled.

	useEffect(() => {
		// Verify we are in a valid state when putting together a hardware test result
		if (status === HardwareTestStatus.testing || !hasAutoSelected) {
			return;
		}

		const cameraLabel = isVideoEnabled && selectedCameraLabel ? selectedCameraLabel : "";
		const speakerLabel = selectedSpeakerLabel ?? "";
		const isSuccess = status === HardwareTestStatus.passed;

		setHardwareTest((prevTest) => {
			const currentTest = {
				success: isSuccess,
				camera: {
					failure: cameraFailure,
					label: cameraLabel,
				},
				microphone: {
					failure: micFailure,
					label: selectedMicLabel,
				},
				speaker: {
					failure: speakerFailure,
					label: speakerLabel,
				},
				errorCode:
					vendorError?.vendorErrorCode ??
					(cameraFailure || micFailure || speakerFailure ? "11" : ""),
				errorMessage:
					vendorError?.message ??
					constructErrorMessageForHWTest(cameraError, micError, speakerError),
				userLanguage: navigator.language,
				// this will be overridden, by our calculation in the next step, we don't have to worry about it
				shouldLogToEpic: !prevTest?.success || !isSuccess,
			};

			const shouldLog = shouldLogToEpic(prevTest, currentTest, connectionStatus === "connected");

			return {
				...currentTest,
				shouldLogToEpic: shouldLog,
			};
		});
	}, [
		status,
		hasAutoSelected,
		cameraFailure,
		cameraError,
		micFailure,
		micError,
		selectedSpeakerLabel,
		speakerFailure,
		speakerError,
		vendorError,
		localDeviceStream,
		isVideoEnabled,
		selectedCameraLabel,
		selectedMicLabel,
		connectionStatus,
	]);

	return hardwareTest;
}
