/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Returns a callback to facilitate a patient joining a visit after being granted access
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.Moderation.UseJoinVisitHandler
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext } from "react";
import { useLocalAudioToggle, useLocalVideoToggle } from "~/hooks";
import { alertActions, useAuthState, userActions } from "~/state";
import { IModeratorPayload } from "~/types";
import { isEmbeddedIOS } from "~/utils/browser";
import { secondsToMs } from "~/utils/dateTime";
import frameMessager from "~/utils/frameMessager";
import { iOSVerMediaRecycleWorkarounds } from "~/utils/os";
import { makeRequest } from "~/utils/request";
import { delay } from "~/utils/timer";
import { VideoSessionContext } from "~/web-core/components";
import { DailySession } from "~/web-core/vendor/daily/implementations/dailySession";
import { useBroadcastParticipantInfo } from "../useBroadcastParticipantInfo";

const EmbeddedIOSSubscriptionToggleDelayMs = secondsToMs(3);

export function useJoinVisitHandler(): (payload: IModeratorPayload) => Promise<void> {
	const { session } = useContext(VideoSessionContext);
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const broadcastInfo = useBroadcastParticipantInfo();
	const [, toggleMic] = useLocalAudioToggle();
	const [, toggleVideo] = useLocalVideoToggle();

	const dispatch = useDispatch();

	const joinCallback = useCallback(
		async (payload: IModeratorPayload) => {
			const { audioLock, videoLock, screenShareLock } = payload;

			if (session instanceof DailySession && JWT) {
				await session?.enableUserMedia(session.localUser.getUserIdentity(), JWT);
			}

			// Update local state
			dispatch(userActions.setUserActiveInCall(payload));

			dispatch(alertActions.setAdmittedAlert(true));

			// Update in-call status for all remote users
			broadcastInfo({
				newWaitingState: false,
				newDisabledState: videoLock,
				newMutedState: audioLock,
				newScreenState: screenShareLock,
			});

			frameMessager.postMessage("Epic.Video.Connected");

			// Control media
			if (audioLock) {
				await toggleMic("off");
			}

			const localStream = session?.getLocalParticipant()?.deviceStream;
			if (localStream) {
				await session?.publish("audio", true, localStream, iOSVerMediaRecycleWorkarounds);
			}
			if (videoLock) {
				await toggleVideo("off");
			} else if (localStream) {
				await session?.publish("video", true, localStream, iOSVerMediaRecycleWorkarounds);
			}

			if (screenShareLock) {
				session?.localUser.cleanupShareStream();
			}

			if (JWT && isEmbeddedIOS()) {
				await delay(EmbeddedIOSSubscriptionToggleDelayMs);
				await toggleRemoteMedia(JWT);
			}
		},
		[JWT, broadcastInfo, dispatch, session, toggleMic, toggleVideo],
	);

	return joinCallback;
}

/**
 * Toggles the current user's track subscriptions to allow them to reset any stale values within the room
 */
async function toggleRemoteMedia(jwt: string): Promise<void> {
	return makeRequest<void>("api/VideoCall/MediaSubscriptions/Toggle", "POST", jwt);
}
