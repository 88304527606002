/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Types for Image Capture
 * @author Will Cooper
 * @module Epic.VideoApp.Types.ImageCapture
 */

export interface ITelemedicineDocType {
	title: string;
	id: string;
}

export interface IImageCaptureAlertData {
	docTypeId: string;
	description: string;
}

export interface IMediaTokenResponse {
	mediaToken: string;
}

/** This directly matches the number of characters that can be entered when directly adding an image to the Notes activity in Haiku */
export const DescriptionMaxLength = 255;
