/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Local User for Daily Sessions
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Implementations.DailyLocalUser
 */

import Daily, { DailyCall, DailyEventObjectNetworkQualityEvent, DailyParticipant } from "@daily-co/daily-js";
import { BackgroundSettings } from "~/types/backgrounds";
import { EVCEmitter, IEVCUserEventMap } from "~/web-core/events";
import { ILocalStream, ILocalUser } from "~/web-core/interfaces";
import { DailyBackgroundManager } from "../helpers/dailyBackgroundManager";
import { DailyLocalStream } from "./dailyLocalStream";

export class DailyLocalUser extends EVCEmitter<IEVCUserEventMap> implements ILocalUser {
	deviceStream: DailyLocalStream;
	shareStream: DailyLocalStream | null;
	dailyCall: DailyCall;
	participant: DailyParticipant | null;
	readonly isLocal: true = true;
	private _networkQuality: number = 4;
	// A separate DailyCall instance will allow for preview tracks to be applied without publishing them
	private _previewTrackCall: DailyCall;
	private _backgroundLoader: DailyBackgroundManager = new DailyBackgroundManager();

	constructor(stream: DailyLocalStream) {
		super();
		this.deviceStream = stream;
		this.shareStream = null;
		this.dailyCall = stream.call;
		this._previewTrackCall = Daily.createCallObject({
			allowMultipleCallInstances: true,
			subscribeToTracksAutomatically: false,
			startVideoOff: true,
			startAudioOff: true,
			dailyConfig: { alwaysIncludeMicInPermissionPrompt: false },
		});
		this.participant = stream.call.participants().local;
		this.constructEmitterInterface();
	}

	/**
	 * Initializes the DailyLocalUser by starting the camera for the preview track call
	 */
	async initializeDaily(): Promise<void> {
		await this._previewTrackCall.startCamera();
	}

	async createDevicePreviewStream(
		disabledCameraId: string | null,
		backgroundSettings?: BackgroundSettings,
	): Promise<ILocalStream> {
		const stream = new DailyLocalStream("camera", this._previewTrackCall);
		const cameraDeviceId = disabledCameraId ?? this.deviceStream.getDeviceId("video");
		const cameraDeviceInfo = await navigator.mediaDevices.enumerateDevices().then((devices) => {
			return devices.find((device) => device.deviceId === cameraDeviceId);
		});
		if (!cameraDeviceInfo) {
			throw new Error("No camera device found with the provided ID");
		}
		// Passing the background settings early allow for a smaller delay to apply the background when making the preview
		const arrayBuffer = this._backgroundLoader.backgroundImageBytesMap.get(
			backgroundSettings?.path ?? "",
		);
		stream.setBackgroundEffectInformation(backgroundSettings ?? null, arrayBuffer ?? null);
		const response = await stream.switchVideoDeviceAsync(cameraDeviceInfo);
		if (response.error) {
			throw response.error;
		}
		this._previewTrackCall.setLocalVideo(true);
		stream.videoDevice = this._previewTrackCall.participants().local.tracks.video;
		return Promise.resolve(stream);
	}

	async applyVideoBackground(
		settings: BackgroundSettings | null,
		stream: ILocalStream | null,
	): Promise<void> {
		const arrayBuffer = this._backgroundLoader.backgroundImageBytesMap.get(settings?.path ?? "");

		/*
		 * By always updating the device stream's background settings, even when working with a preview stream,
		 * we don't have to apply a null background every time the camera is turned off since the background
		 * settings will always be up to date. This improves behavior on low-end hardware.
		 */
		this.deviceStream.setBackgroundEffectInformation(settings, arrayBuffer ?? null);

		if (stream && stream instanceof DailyLocalStream) {
			await stream.applyVideoBackground(settings, arrayBuffer);
		}
	}

	async initializeVirtualBackgrounds(
		backgroundSettings: BackgroundSettings[],
		availableBackgroundsCount: number,
	): Promise<void> {
		this._backgroundLoader.once("backgroundProcessorsDone", (args) => {
			this.emit("backgroundProcessorsDone", args);
		});
		await this._backgroundLoader.initializeBackgroundProcessors(
			backgroundSettings,
			availableBackgroundsCount,
		);
	}

	createShareStream(
		videoStream: MediaStreamTrack,
		audioStream: MediaStreamTrack | null,
		mediaStream: MediaStream,
	): Promise<ILocalStream> {
		const stream = new DailyLocalStream("screen");
		stream.mediaStream = mediaStream;
		stream.videoDevice = { subscribed: "staged", state: "playable" };
		stream.videoDevice.persistentTrack = videoStream;
		if (audioStream) {
			stream.audioDevice = { subscribed: "staged", state: "playable" };
			stream.audioDevice.persistentTrack = audioStream;
		}

		this.shareStream = stream;
		this.emit("participantUpdated", {
			type: "participantUpdated",
			participant: this,
			videoType: "screen",
		});

		return Promise.resolve(stream);
	}

	cleanupShareStream(): void {
		void this.shareStream?.cleanUp();
		this.shareStream = null;
		this.emit("participantUpdated", {
			type: "participantUpdated",
			participant: this,
			videoType: "screen",
		});
	}

	getUserIdentity(): string {
		return this.participant?.user_id ?? "";
	}
	isSharingScreen(): boolean {
		return this.shareStream !== null;
	}

	getNetworkQualityLevel(): number {
		return this._networkQuality;
	}

	getUserGuid(): string {
		return this.participant?.session_id ?? "";
	}

	setAudioOutput(speaker: MediaDeviceInfo): void {
		void this.dailyCall?.setOutputDeviceAsync({ outputDeviceId: speaker.deviceId });
		void this._previewTrackCall?.setOutputDeviceAsync({ outputDeviceId: speaker.deviceId });
	}

	/**
	 * Constructs an interface layer to convert vendor-constructed events into shared events as defined by userEvents.ts
	 */
	private constructEmitterInterface(): () => void {
		const handleConnectionQualityChanged = (event?: DailyEventObjectNetworkQualityEvent): void => {
			let EVCQuality = 4;
			if (event) {
				if (event.quality < 10) {
					EVCQuality = 0;
				} else if (event.quality < 25) {
					EVCQuality = 1;
				} else if (event.quality < 50) {
					EVCQuality = 2;
				} else if (event.quality < 75) {
					EVCQuality = 3;
				} else {
					EVCQuality = 4;
				}
			}
			// Only emit the event if the mapped quality has changed
			if (this._networkQuality === EVCQuality) {
				return;
			}
			this._networkQuality = EVCQuality;
			this.emit("networkQualityLevelChanged", {
				type: "networkQualityLevelChanged",
				newValue: EVCQuality,
			});
		};

		this.dailyCall.on("network-quality-change", handleConnectionQualityChanged.bind(this));
		return () => {
			this.dailyCall.off("network-quality-change", handleConnectionQualityChanged);
		};
	}
}
