/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Copyright notice at the bottom of the screen
 * @author Jason Guo + Sasha Chackalackal
 * @module Epic.VideoApp.Components.Copyright.CopyrightLinkButton
 */

import React, { FC, useCallback, useState } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import { useStrings } from "~/hooks";
import { resolveClassName } from "~/utils/className";
import { copyrightNotice, fullCopyrightNotice, fullCopyrightNoticeAriaLabel } from "~/utils/copyright";
import GenericPopup from "../Utilities/GenericPopup";
import styles from "./Copyright.module.scss";

interface IProps {
	hardwareTestStyling?: boolean;
}

enum TokenNames {
	closePopup = "ClosePopup",
}

/**
 * Copyright notice at the bottom of the screen
 */
const CopyrightLinkButton: FC<IProps> = (props: IProps) => {
	const { hardwareTestStyling } = props;
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const onClick = useCallback(() => {
		setIsPopupOpen(true);
	}, []);

	const onPopupClose = useCallback(() => {
		setIsPopupOpen(false);
	}, []);

	const strings = useStrings("CopyrightLinkButton", Object.values(TokenNames));

	const styling = resolveClassName(styles, { copyright: true, isMobile: hardwareTestStyling });

	return (
		<>
			<GenericPopup
				message={fullCopyrightNotice}
				ariaLabel={fullCopyrightNoticeAriaLabel}
				isOpen={isPopupOpen}
				onClose={onPopupClose}
				confirmText={strings[TokenNames.closePopup]}
				isLTR
			/>
			<BaseButton onClick={onClick} text={copyrightNotice} rawClass={styling} noStyle isLTR />
		</>
	);
};

CopyrightLinkButton.displayName = "CopyrightLinkButton";

export default CopyrightLinkButton;
