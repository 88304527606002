/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Implements error handling utilities for Daily
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Functions.DailyErrorUtils
 */

import {
	DailyCamConstraintsError,
	DailyCamDeviceNotFoundError,
	DailyCameraErrorObject,
	DailyCameraErrorType,
	DailyCamInUseError,
	DailyCamPermissionsError,
	DailyCamTypeError,
	DailyCamUnknownError,
	DailyDeviceInfos,
	DailyEventObjectFatalError,
	DailyEventObjectGenericError,
	DailyFatalErrorType,
	DailyMediaDeviceInfo,
} from "@daily-co/daily-js";
import { DeviceStatus, DeviceStatusSubtype } from "~/types";
import { SessionErrorCodes, VendorError } from "~/web-core/interfaces";
import { IDeviceUpdate } from "../../twilio";

export const DailyDuplicateErrorMessage = "Duplicate user_id";

/**
 * Return a generic Error from a Daily camera switching error
 * @param error Error return type from attempts to switch a camera with the Daily API
 * @returns Daily error message wrapped in a generic Error
 */
export function makeDailyCamErrorGeneric(
	error:
		| DailyCamPermissionsError
		| DailyCamDeviceNotFoundError
		| DailyCamConstraintsError
		| DailyCamInUseError
		| DailyCamTypeError
		| DailyCamUnknownError
		| undefined,
): Error | undefined {
	if (!error) {
		return undefined;
	}
	return new Error(error.msg);
}

/**
 * Process Daily errors into generic device updates
 */
export function processDailyError(
	deviceErrors: DailyCameraErrorObject<DailyCameraErrorType>[],
): IDeviceUpdate[] {
	const updates: IDeviceUpdate[] = [];
	deviceErrors.forEach((error) => {
		switch (error.type) {
			case "permissions":
				if (error.blockedMedia.includes("video")) {
					updates.push({
						device: "camera",
						status: DeviceStatus.error,
						errorType: DeviceStatusSubtype.permissionsError,
					});
				}
				if (error.blockedMedia.includes("audio")) {
					updates.push({
						device: "mic",
						status: DeviceStatus.error,
						errorType: DeviceStatusSubtype.permissionsError,
					});
				}
				break;
			case "not-found":
				if (error.missingMedia.includes("video")) {
					updates.push({
						device: "camera",
						status: DeviceStatus.error,
						errorType: DeviceStatusSubtype.general,
					});
				}
				if (error.missingMedia.includes("audio")) {
					updates.push({
						device: "mic",
						status: DeviceStatus.error,
						errorType: DeviceStatusSubtype.general,
					});
				}
				break;
			case "cam-in-use":
				updates.push({
					device: "camera",
					status: DeviceStatus.error,
					errorType: DeviceStatusSubtype.hardwareError,
				});
				break;
			case "mic-in-use":
				updates.push({
					device: "mic",
					status: DeviceStatus.error,
					errorType: DeviceStatusSubtype.hardwareError,
				});
				break;
			case "cam-mic-in-use":
				updates.push({
					device: "camera",
					status: DeviceStatus.error,
					errorType: DeviceStatusSubtype.hardwareError,
				});
				updates.push({
					device: "mic",
					status: DeviceStatus.error,
					errorType: DeviceStatusSubtype.hardwareError,
				});
				break;
			default:
				updates.push({
					device: "camera",
					status: DeviceStatus.error,
					errorType: DeviceStatusSubtype.unknown,
				});
				updates.push({
					device: "mic",
					status: DeviceStatus.error,
					errorType: DeviceStatusSubtype.unknown,
				});
				break;
		}
	});
	return updates;
}

/**
 * Map errors from Daily workflows to generic error codes
 * @param error - A potentially any/unknown type (see DailyFatalError and DailyEventObjectFatalError) or a traditional Error
 * @returns - A VendorError object with the corresponding error code
 */
export function dailyErrorToVendorError(error: unknown): VendorError {
	if ((error as IDailyFatalError)?.type !== undefined) {
		return dailyFatalErrorToVendorError(error as IDailyFatalError);
	} else if ((error as DailyEventObjectFatalError)?.action === "error") {
		const dailyEventErrorObject = error as DailyEventObjectFatalError;
		if (dailyEventErrorObject?.error !== undefined) {
			return dailyFatalErrorToVendorError(dailyEventErrorObject.error as IDailyFatalError);
		}

		return new VendorError(
			dailyEventErrorObject.action,
			SessionErrorCodes.unknown,
			dailyEventErrorObject.errorMsg ?? "Unknown error",
		);
	} else if ((error as DailyEventObjectGenericError)?.action === "load-attempt-failed") {
		const loadError = error as DailyEventObjectGenericError;
		return new VendorError(
			loadError.action,
			SessionErrorCodes.failedToJoinRoom,
			loadError.errorMsg ?? "Failed to join room",
			loadError.action,
		);
	} else {
		return new VendorError("", SessionErrorCodes.unknown, "Unknown error", "Unknown");
	}
}

/**
 * Validate and report invalid devices in a Daily device info object
 * @param cameraInfo - The Daily device info object to validate
 * @param errorArr - An array of Daily errors to add to if invalid devices are found and are not already listed in the array
 */
export function validateAndReportInvalidDevices(
	cameraInfo: DailyDeviceInfos,
	errorArr: DailyCameraErrorObject<DailyCameraErrorType>[],
): void {
	// Add a device not found error if there aren't other camera related errors reported by Daily but the camera device is invalid
	if ((errorArr.length === 0 || !isDailyCamError(errorArr[0])) && !hasDailyValidCam(cameraInfo)) {
		addDailyDeviceNotFoundError(errorArr, "video");
	}
	// Similarly, add a device not found error if there aren't other microphone related errors reported by Daily but the microphone device is invalid
	if ((errorArr.length === 0 || !isDailyMicError(errorArr[0])) && !hasDailyValidMic(cameraInfo)) {
		addDailyDeviceNotFoundError(errorArr, "audio");
	}
}

/**
 * Map error codes from Daily to the generic error types EVC runs on.
 * @param dailyErrorCode - The string code of the daily error
 * @returns - The corresponding EVC error code, or unknown if not found.
 */
function dailyFatalErrorToVendorError(dailyError: IDailyFatalError): VendorError {
	switch (dailyError.type) {
		case "ejected":
			return new VendorError(
				dailyError.type,
				dailyError.msg === "Duplicate user_id"
					? SessionErrorCodes.participantDuplicateIdentityError
					: SessionErrorCodes.participantRemovedFromSession,
				dailyError.msg ?? "Participant removed from session",
			);
		case "no-room":
			return new VendorError(
				dailyError.type,
				SessionErrorCodes.roomCompletedError,
				dailyError.msg ?? "Room completed error",
			);
		case "meeting-full":
			return new VendorError(
				dailyError.type,
				SessionErrorCodes.roomMaxParticipantsExceededError,
				dailyError.msg ?? "Room max participants exceeded error",
			);
		case "connection-error":
			return new VendorError(
				dailyError.type,
				SessionErrorCodes.failedToJoinRoom,
				dailyError.msg ?? "Failed to join room",
			);
		default:
			return new VendorError(
				dailyError.type,
				SessionErrorCodes.unknown,
				dailyError.msg ?? "Unknown error",
			);
	}
}

/**
 * Check if a Daily device info object has a valid camera device
 * @param cameraInfo - The Daily device info object to check
 * @returns - True if the camera device has a valid deviceId, false otherwise
 */
function hasDailyValidCam(cameraInfo: DailyDeviceInfos): boolean {
	return (cameraInfo.camera as DailyMediaDeviceInfo).deviceId ? true : false;
}

/**
 * Check if a Daily device info object has a valid microphone device
 * @param cameraInfo - The Daily device info object to check
 * @returns - True if the microphone device has a valid deviceId, false otherwise
 */
function hasDailyValidMic(cameraInfo: DailyDeviceInfos): boolean {
	return (cameraInfo.mic as DailyMediaDeviceInfo).deviceId ? true : false;
}

/**
 * Check if a Daily error object is related to Camera device
 * @param error - The Daily error object to check
 * @returns - True if the error is related to a camera device, false otherwise
 */
export function isDailyCamError(error: DailyCameraErrorObject<DailyCameraErrorType>): boolean {
	return (
		(error.type === "permissions" && error.blockedMedia.includes("video")) ||
		(error.type === "not-found" && error.missingMedia.includes("video")) ||
		error.type === "cam-in-use" ||
		error.type === "cam-mic-in-use"
	);
}

/**
 * Check if a Daily error object is related to Microphone device
 * @param error - The Daily error object to check
 * @returns - True if the error is related to a microphone device, false otherwise
 */
export function isDailyMicError(error: DailyCameraErrorObject<DailyCameraErrorType>): boolean {
	return (
		(error.type === "permissions" && error.blockedMedia.includes("audio")) ||
		(error.type === "not-found" && error.missingMedia.includes("audio")) ||
		error.type === "mic-in-use" ||
		error.type === "cam-mic-in-use"
	);
}

/**
 * Add a Daily "not found" device error object to an array of Daily errors for a camera or microphone device
 */
function addDailyDeviceNotFoundError(
	errorArr: DailyCameraErrorObject<DailyCameraErrorType>[],
	mediaType: "audio" | "video",
): void {
	errorArr.push({
		type: "not-found",
		missingMedia: [mediaType],
		msg: "No " + mediaType + " devices found",
	});
}
export interface IDailyFatalError {
	type: DailyFatalErrorType;
	msg: string;
}
