/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file language selector button
 * @author Razi Rais
 * @module Epic.VideoApp.Components.Header.Buttons.LanguageSelectorButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useMemo } from "react";
import { useStrings, useWindowSize } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import Globe from "~/icons/globe";
import { uiActions, useUIState } from "~/state";
import { I18n } from "~/utils/i18n";
import ControlButton from "./ControlButton";

/**
 * Props for LanguageSelectorButton component
 */
interface IProps {
	/** The context that the language selector button is being displayed in */
	context: "menu" | "header";

	/** Information on where the button exists in the tab order of the more options menu */
	tabString?: string;
}

// button's ID in HTML used to focus it from BaseMenu
export const languagesButtonId = "languages-button";

// the minimum width that the full button label should be shown when the button is in the header
const MinWidthForLabelInHeader = 1600;

/** String tokens used by the LanguageSelectorButton component */
enum TokenNames {
	dropdownButtonLabel = "ButtonLabel",
	usageLabel = "LabelNoHotkey",
}

/**
 * language selector button
 */
const LanguageSelectorButton: FC<IProps> = (props: IProps) => {
	const { context, tabString } = props;
	const { width } = useWindowSize();
	const visibleMenu = useUIState((selectors) => selectors.getVisibleMenu(), []);
	const canChangeLocales = useMemo(() => I18n.canChangeLocales(), []);
	const dispatch = useDispatch();

	const strings = useStrings("LanguageSelectorButton", Object.values(TokenNames));

	const keyboardShortcut = ["alt", "g"];
	// The formatted label with more information and hotkeys should only be used in the header
	const usageLabelFormatted = useGetFormattedHotkeyString(strings[TokenNames.usageLabel], keyboardShortcut);

	const onClick = useCallback(() => {
		dispatch(uiActions.toggleVisibleMenu({ menu: "languages" }));
	}, [dispatch]);

	if (!canChangeLocales) {
		return null;
	}

	const pressed = visibleMenu === "languages";
	const showText = context === "menu" || width >= MinWidthForLabelInHeader;

	return (
		<ControlButton
			id={languagesButtonId}
			icon={Globe}
			onClick={onClick}
			tooltipText={context === "header" && !pressed ? usageLabelFormatted : undefined}
			ariaLabel={
				context === "header"
					? usageLabelFormatted
					: strings[TokenNames.usageLabel] + String(tabString)
			}
			ariaExpanded={pressed}
			keyboardShortcut={keyboardShortcut}
			supplementaryIcon={context === "menu" ? "continue" : pressed ? "chevron-up" : "chevron-down"}
			buttonText={showText ? strings[TokenNames.dropdownButtonLabel] : undefined}
		/>
	);
};

LanguageSelectorButton.displayName = "LanguageSelectorButton";

export default LanguageSelectorButton;
